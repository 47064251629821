export default function (state = [], action) {
  switch (action.type) {
    case 'UPDATE_COOKIE_CONSENT':
      console.log('cookieConsents reducer', action.cookieConsents)
      return action.cookieConsents

    case 'CLEAR_ALL_DATA':
      return []

    default:
      return state
  }
}
