import store from '../store'

const { compact } = require('lodash')

const categoryMap = {
  necessary: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
  socialmedia: 'C0005',
  youtube: 'P0002',
}

if (!window.cookieHelperConsentedGroups || window.cookieHelperConsentedGroups.length <= 0) {
  window.cookieHelperConsentedGroups = getActiveGroups()
  updateStoreOnConsentChange(window.cookieHelperConsentedGroups)
}

if (!window.cookieHelperConsentUpdateHook) {
  window.cookieHelperConsentUpdateHook = updateStoreOnConsentChange
}

function updateStoreOnConsentChange(consentedGroups) {
  const newCookieConsents = consentedGroups || getActiveGroups()
  store.dispatch({
    type: 'UPDATE_COOKIE_CONSENT',
    cookieConsents: newCookieConsents,
  })
}

function revisitConsent() {
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay()
  }
}

function getActiveGroups() {
  if (window.OnetrustActiveGroups) {
    return compact(window.OnetrustActiveGroups.split(','))
  }
  return []
}

function isCategoryConsented(category, consentedGroups) {
  const categoryCode = categoryMap[category]
  const activeGroups = consentedGroups || getActiveGroups()
  return activeGroups.includes(categoryCode)
}

function getCookieSettingsButtonText(options = {}) {
  if (isUnderCcpa()) {
    if (options.shortText) {
      return 'Your Privacy Choices'
    }
    return 'Do Not Sell Or Share My Personal Information'
  }
  return 'Cookie Settings'
}

function isUnderCcpa() {
  // For now we are disabling this the ccpa notif. in favor of having all users same experience
  // but we are keeping the code in case we need to enable it in the future
  // when the responsible authorities makes their mind.
  // if (window.OneTrust) {
  //   const oneTrustDomainData = window.OneTrust.GetDomainData()
  //   return oneTrustDomainData.CookieSettingButtonText.includes('Do Not Sell')
  // }
  return false
}

function isContentAllowed(contentType, consentedGroups) {
  switch (contentType) {
    case 'youtube':
      return isCategoryConsented('youtube', consentedGroups)
    default:
      return false
  }
}

export {
  isContentAllowed,
  revisitConsent,
  getCookieSettingsButtonText,
  isUnderCcpa,
}
