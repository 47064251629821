import api from '../api'

function patchRetry(url, data, studentId, pollId) {
  const delay = 1 // delay in ms before retry request
  const totalRetries = 10
  const limit = 10 // max number request repeats
  return new Promise(((resolve, reject) => {
    const wrappedPatch = function (limit) {
      api.patch(url, data)
        .then((response) => {
          resolve({ response, retries: (totalRetries - limit) })
        })
        .catch((error) => {
          const status = error?.response?.status || 'none'
          if (window.analytics) {
            window.analytics.track('Add Response error', {
              status,
              studentId,
              requestData: data,
              pollId,
              online: navigator.onLine || 'offline',
              retries: totalRetries - limit,
            })
          }
          if (status === 409 && limit > 0) {
            retry(limit)
          } else {
            reject(error)
          }
        })
    }

    function retry(n) {
      if (process.env.REACT_APP_ENV !== 'production') {
        console.log(`************Retry request: attempt ${limit - n + 1} *******************`)
      }
      setTimeout(() => {
        wrappedPatch(n - 1)
      }, delay)
    }
    wrappedPatch(limit)
  }))
}

export function addStudentSetPollResponse(setPollId, setPollPollId, choice, studentId, sectionId) {
  const nowDate = new Date()
  const data = { captured: nowDate, answer: choice }
  return (/* dispatch */) => patchRetry(`/student/setPolls/${setPollId}/polls/${setPollPollId}`, data, studentId, setPollPollId)
    .then((response) => {
      if (window.analytics) {
        window.analytics.track('Add Response', {
          itemType: 'set',
          studentId,
          setPollId,
          setPollPollId,
          pollId: null,
          sectionId,
          retries: response.retries,
        })
      }
    })
    .catch(() => {})
}

export function addStudentPollResponse(pollId, choice, studentId, sectionId) {
  const nowDate = new Date()
  const data = { captured: nowDate, answer: choice }
  return (/* dispatch */) => patchRetry(`/student/polls/${pollId}`, data, studentId, pollId)
    .then((response) => {
      if (window.analytics) {
        window.analytics.track('Add Response', {
          itemType: 'question',
          studentId,
          setPollId: null,
          setPollPollId: null,
          pollId,
          sectionId,
          retries: response.retries,
        })
      }
    })
    .catch(() => {})
}
