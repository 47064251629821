import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import session from './session'
import sections from './sections'
import pusher from './pusher'
import token from './token'
import clockSyncOffset from './clockSyncOffset'
import cookieConsents from './cookieConsents'

const rootReducer = combineReducers({
  session,
  clockSyncOffset,
  sections,
  pusher,
  token,
  routing: routerReducer,
  cookieConsents,
})

export default rootReducer
