import React from 'react'
import { connect } from 'react-redux'
import StaticLetterBlock from './StaticLetterBlock'

import createMarkup from './createMarkup'

// CHOICE BUTTON
import ChoiceButton from './ChoiceButton'

// QUESTION MEDIA
import StaticSlideImage from './media/image/StaticSlideImage'
import StaticSlideZoomableFitImage from './media/image/StaticSlideZoomableFitImage'
import StaticSlideZoomableFillImage from './media/image/StaticSlideZoomableFillImage'
import StaticSlideAnimatedGIF from './media/gif/StaticSlideAnimatedGIF'
import StaticSlideSoundPlayer from './media/sound/StaticSlideSoundPlayer'
import StaticSlideSoundZoomablePlayer from './media/sound/StaticSlideSoundZoomablePlayer'
import StaticSlideSoundStatic from './media/sound/StaticSlideSoundStatic'
import StaticSlideVideoPlayer from './media/video/StaticSlideVideoPlayer'
import StaticSlideVideoZoomablePlayer from './media/video/StaticSlideVideoZoomablePlayer'
import StaticSlideVideoStatic from './media/video/StaticSlideVideoStatic'

// CHOICE MEDIA
import StaticSlideChoiceImage from './media/image/StaticSlideChoiceImage'
import StaticSlideChoiceZoomableFitImage from './media/image/StaticSlideChoiceZoomableFitImage'
import StaticSlideChoiceZoomableFillImage from './media/image/StaticSlideChoiceZoomableFillImage'
import StaticSlideChoiceAnimatedGIF from './media/gif/StaticSlideChoiceAnimatedGIF'
import StaticSlideChoiceSoundPlayer from './media/sound/StaticSlideChoiceSoundPlayer'
import StaticSlideChoiceSoundZoomablePlayer from './media/sound/StaticSlideChoiceSoundZoomablePlayer'
import StaticSlideChoiceSoundStatic from './media/sound/StaticSlideChoiceSoundStatic'
import StaticSlideChoiceVideoPlayer from './media/video/StaticSlideChoiceVideoPlayer'
import StaticSlideChoiceVideoZoomablePlayer from './media/video/StaticSlideChoiceVideoZoomablePlayer'
import StaticSlideChoiceVideoStatic from './media/video/StaticSlideChoiceVideoStatic'

import { isContentAllowed } from '../../utils/cookieHelpers'
import YoutubeBlocked from '../misc/ContentBlocked'

// ZOOM CHOICE TOGGLE
// import StaticSlideZoomChoicesChoiceToggle from './StaticSlideZoomChoicesChoiceToggle'

const DEFAULT_SLIDE_WIDTH = 1280

class RichStaticSlide extends React.Component {
  constructor(props) {
    super(props)
    this.updateQuestionFontSizes = this.updateQuestionFontSizes.bind(this)
    this.updateQuestionMediaDynamicHeight = this.updateQuestionMediaDynamicHeight.bind(this)
    this.onMouseEnterChoice = this.onMouseEnterChoice.bind(this)
    this.onMouseEnterChoicesGroup = this.onMouseEnterChoicesGroup.bind(this)
    this.onMouseLeaveChoicesGroup = this.onMouseLeaveChoicesGroup.bind(this)
    let bodyFontSize = 64
    let choiceFontSize = 42
    let questionMediaDynamicHeight = 0
    if (props.question.measurements) {
      bodyFontSize = props.question.measurements.bodyFS
      choiceFontSize = props.question.measurements.choiceFS
      questionMediaDynamicHeight = props.question.measurements.questionMediaDynamicHeight
    }
    this.state = {
      bodyFontSize,
      choiceFontSize,
      questionMediaDynamicHeight,
    }
  }

  onMouseEnterChoice(choiceLetter) {
    if (this.props.onMouseEnterChoice) {
      this.props.onMouseEnterChoice(choiceLetter)
    }
  }

  onMouseEnterChoicesGroup() {
    if (this.props.onMouseEnterChoicesGroup) {
      this.props.onMouseEnterChoicesGroup()
    }
  }

  onMouseLeaveChoicesGroup() {
    if (this.props.onMouseLeaveChoicesGroup) {
      this.props.onMouseLeaveChoicesGroup()
    }
  }

  updateQuestionFontSizes(perfectCombo) {
    this.setState({ bodyFontSize: perfectCombo.bodyFontSize })
    this.setState({ choiceFontSize: perfectCombo.choiceFontSize })
  }

  updateQuestionMediaDynamicHeight(questionMediaDynamicHeight) {
    this.setState({ questionMediaDynamicHeight })
  }

  render() {
    const {
      question,
      // viewType,
      slideWidth,
      mediaIsPlayable,
      mediaIsZoomable,
      choiceMediaZoomed,
      activeZoomedChoiceIndex,
      isAskScreen,
      isScanScreen,
      showResponseCounts,
      showGraph,
      colorScheme,
      // processedResponses,
      // totalResponses,
      // nowPlayingView,
      reportView,
      // expectedResponses,
      playbackItem,
      isPlaying,
      onSelectChoice,
      // isSelected,
      selectedChoice,
      cookieConsents,
    } = this.props

    const isYoutubeAllowed = isContentAllowed('youtube', cookieConsents)

    const slideScale = slideWidth / DEFAULT_SLIDE_WIDTH
    const inverseScale = 1 / slideScale
    // prevent and other elements icons getting too big
    const ceilingInverseScale = 1.55
    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)
    const bodyHTML = question.bodyHtml
    const slideTemplate = question.layout
    const isSurvey = question.survey

    let bodyFontSize
    let choiceFontSize
    let questionMediaDynamicHeight
    // use this instead of state for the FS visualiser where xLayout is recalculated
    if (question.measurements) {
      bodyFontSize = question.measurements.bodyFS
      choiceFontSize = question.measurements.choiceFS
      questionMediaDynamicHeight = question.measurements.questionMediaDynamicHeight
    } else {
      bodyFontSize = this.state.bodyFontSize
      choiceFontSize = this.state.choiceFontSize
      questionMediaDynamicHeight = this.state.questionMediaDynamicHeight
    }
    let bodyMediaType
    const { media } = question
    const hasBodyMedia = !!media
    if (hasBodyMedia && media.type === 'image') {
      bodyMediaType = 'image'
    }
    if (hasBodyMedia && media.type === 'animatedGif') {
      bodyMediaType = 'animatedGif'
    } else if (hasBodyMedia && media.type === 'sound') { // TODO -clipping
      bodyMediaType = 'sound'
    } else if (hasBodyMedia && media.type === 'video') {
      bodyMediaType = 'video'
    }

    const templateDefinition = slideTemplate // taken from Main React getTemplateDefinition
    const isChoiceMedia = (slideTemplate === 'bodyCenterChoicesMedia' || slideTemplate === 'bodyLeftMediaRightChoicesMedia')

    const choiceCount = question.choices.length

    // to do: bring in from slide definitions
    let mediaChoiceWidth = 285
    if (choiceCount === 3) {
      mediaChoiceWidth = 365
    }
    if (choiceCount === 2) {
      mediaChoiceWidth = 505
    }

    let choiceStyle
    if (isChoiceMedia) {
      choiceStyle = { width: `${mediaChoiceWidth}px` }
    }

    // ZOOM CHOICES MEDIA

    const slideChoicesStyle = {
      position: 'relative',
      zIndex: choiceMediaZoomed ? 9000 : 0,
    }

    const {
      alwaysShowLetterblock, showCorrect, isReviewScreen, totalResponseCount, results,
    } = this.props
    // const displaySyncGraph = false // I think something for e-Learning teacher view on chioces
    const deviceHasSelectedChoice = !!this.props.selectedChoice

    return (
      <div
        style={{ transform: `scale(${slideScale})` }}
        className={`slide notranslate slide--static${templateDefinition ? ` slide--template--${templateDefinition}` : ''}${reportView ? ' slide--reportView ' : ''}${deviceHasSelectedChoice ? ' slide--hasDeviceSelectedChoice' : ' slide--noDeviceSelectedChoice'}`}
      >

        {/* BODY */}
        <div
          dangerouslySetInnerHTML={createMarkup(bodyHTML)}
          className="slide-body"
          style={{ fontSize: `${bodyFontSize}px` }}
        />

        {/* MEDIA */}
        {hasBodyMedia && bodyMediaType === 'image' && !mediaIsZoomable && (
          <div className="slide-mediaContainer slide-mediaContainer--image">
            <StaticSlideImage
              questionMediaPromiseResolve={this.questionMediaPromiseResolve}
              image={media}
              key={media.fileId}
              UIInverseScale={UIInverseScale}
              slideTemplate={question.layout}
              questionMediaDynamicHeight={questionMediaDynamicHeight}
              imageFillType={question.media.imageFill ? 'fill' : 'fit'}
            />
          </div>
        )}

        {hasBodyMedia && bodyMediaType === 'image' && mediaIsZoomable && !question.media.imageFill && (
          <div className="slide-mediaContainer slide-mediaContainer--image">
            <StaticSlideZoomableFitImage
              image={media}
              UIInverseScale={UIInverseScale}
              expandImageAvailable
              slideTemplate={question.layout}
              questionMediaDynamicHeight={questionMediaDynamicHeight}
              mediaZoomed={this.props.mediaZoomed}
              imageFillType="fit"
              slideScale={slideScale}
              zoomMedia={() => { this.props.zoomMedia('promptMedia') }}
              xPosSlide={this.props.xPosSlide}
              yPosSlide={this.props.yPosSlide}
              viewportWidth={this.props.viewportWidth}
              viewportHeight={this.props.viewportHeight}
            />
          </div>
        )}

        {hasBodyMedia && bodyMediaType === 'image' && mediaIsZoomable && question.media.imageFill && (
          <div className="slide-mediaContainer slide-mediaContainer--image">
            <StaticSlideZoomableFillImage
              image={media}
              UIInverseScale={UIInverseScale}
              expandImageAvailable
              slideTemplate={question.layout}
              questionMediaDynamicHeight={questionMediaDynamicHeight}
              mediaZoomed={this.props.mediaZoomed}
              imageFillType="fill"
              slideScale={slideScale}
              // zoomMedia={this.props.zoomMedia}
              zoomMedia={() => { this.props.zoomMedia('promptMedia') }}
              xPosSlide={this.props.xPosSlide}
              yPosSlide={this.props.yPosSlide}
              viewportWidth={this.props.viewportWidth}
              viewportHeight={this.props.viewportHeight}
            />
          </div>
        )}

        {hasBodyMedia && bodyMediaType === 'animatedGif' && !mediaIsZoomable && media.gifUrl && (
          <div className="slide-mediaContainer slide-mediaContainer--gif">
            <StaticSlideAnimatedGIF
              questionMediaPromiseResolve={this.questionMediaPromiseResolve}
              gif={media}
              fullyStatic={!mediaIsPlayable}
              UIInverseScale={UIInverseScale}
              slideTemplate={question.layout}
              questionMediaDynamicHeight={questionMediaDynamicHeight}
              gifFillType={question.media.imageFill ? 'fill' : 'fit'}
            />
          </div>
        )}

        {bodyMediaType === 'sound' && mediaIsPlayable && !mediaIsZoomable && (
          <div className="slide-mediaContainer slide-mediaContainer--sound">
            <StaticSlideSoundPlayer
              sound={media}
              slideWidth={slideWidth}
              slideTemplate={question.layout}
              showWaveform // should come from question
              viewportWidth={this.props.viewportWidth}
              viewportHeight={this.props.viewportHeight}
              UIInverseScale={UIInverseScale}
            />
          </div>
        )}

        {bodyMediaType === 'sound' && mediaIsPlayable && mediaIsZoomable && (
          <div className="slide-mediaContainer slide-mediaContainer--sound">
            <StaticSlideSoundZoomablePlayer
              sound={media}
              slideWidth={slideWidth}
              slideTemplate={question.layout}
              showWaveform // should come from question
              viewportWidth={this.props.viewportWidth}
              viewportHeight={this.props.viewportHeight}
              UIInverseScale={UIInverseScale}
              playbackItem={playbackItem}
              isPlaying={isPlaying}
              updateControlPlayback={this.props.updateControlPlayback}
              pageVisibilityState={this.props.pageVisibilityState}
              clientPlaybackCommand={this.props.clientPlaybackCommand}
              clearPlaybackCommand={this.props.clearPlaybackCommand}
            />
          </div>
        )}

        {bodyMediaType === 'sound' && !mediaIsPlayable && (
          <div className="slide-mediaContainer slide-mediaContainer--sound">
            <StaticSlideSoundStatic
              questionMediaPromiseResolve={this.questionMediaPromiseResolve}
              sound={media}
              slideWidth={slideWidth}
              slideTemplate={question.layout}
              showWaveform // should come from question
              UIInverseScale={UIInverseScale}
              questionChoiceCount={choiceCount}
            />
          </div>
        )}

        {bodyMediaType === 'video' && mediaIsPlayable && !mediaIsZoomable && (
          <div className="slide-mediaContainer slide-mediaContainer--video">
            {isYoutubeAllowed ? (
              <StaticSlideVideoPlayer
                slideWidth={slideWidth}
                slideTemplate={question.layout}
                questionMediaDynamicHeight={questionMediaDynamicHeight}
                video={question.media}
                start={question.media.start}
                end={question.media.end}
                videoWidth={640}
                videoHeight={360}
              />
            ) : (
              <YoutubeBlocked />
            )}
          </div>
        )}

        {bodyMediaType === 'video' && mediaIsPlayable && mediaIsZoomable && (
          <div className="slide-mediaContainer slide-mediaContainer--video slide-mediaContainer--video--zoomable">
            {isYoutubeAllowed ? (
              <StaticSlideVideoZoomablePlayer
                slideWidth={slideWidth}
                slideTemplate={question.layout}
                questionMediaDynamicHeight={questionMediaDynamicHeight}
                video={question.media}
                start={question.media.start}
                end={question.media.end}
                videoWidth={640}
                videoHeight={360}
                mediaZoomed={this.props.mediaZoomed}
                zoomMedia={() => { this.props.zoomMedia('promptMedia') }}
                clientPlaybackCommand={this.props.clientPlaybackCommand}
                clearPlaybackCommand={this.props.clearPlaybackCommand}
                xPosSlide={this.props.xPosSlide}
                yPosSlide={this.props.yPosSlide}
                viewportWidth={this.props.viewportWidth}
                viewportHeight={this.props.viewportHeight}
                playbackItem={playbackItem}
                isPlaying={isPlaying}
                updateControlPlayback={this.props.updateControlPlayback}
                pageVisibilityState={this.props.pageVisibilityState}
              />
            ) : (
              <YoutubeBlocked />
            )}
          </div>
        )}

        {bodyMediaType === 'video' && !mediaIsPlayable && (
          <div className="slide-mediaContainer slide-mediaContainer--video">
            {isYoutubeAllowed ? (
              <StaticSlideVideoStatic
                questionMediaPromiseResolve={this.questionMediaPromiseResolve}
                UIInverseScale={UIInverseScale}
                slideWidth={slideWidth}
                slideTemplate={question.layout}
                questionMediaDynamicHeight={questionMediaDynamicHeight}
                video={question.media}
              />
            ) : (
              <YoutubeBlocked />
            )}
          </div>
        )}

        {/* CHOICES */}
        <div
          style={slideChoicesStyle}
          className={`slide-choices ${this.props.choicesGroupHovered ? ' slide-choices--delayHovered ' : ' slide-choices--notDelayHovered '}`}
          onMouseEnter={this.onMouseEnterChoicesGroup}
          onMouseLeave={this.onMouseLeaveChoicesGroup}
        >

          {question.choices.map((choice, key) => {
            let choiceMediaType

            if (isChoiceMedia) {
              if (choice.media && choice.media.type === 'video') {
                choiceMediaType = 'video'
              } else if (choice.media && choice.media.type === 'image') {
                choiceMediaType = 'image'
              } else if (choice.media && choice.media.type === 'animatedGif') {
                choiceMediaType = 'animatedGif'
              } else if (choice.media && choice.media.type === 'sound') {
                choiceMediaType = 'sound'
              }
            }

            let itemName = 'choiceA'
            let choiceLetter = 'A'
            if (key === 1) {
              itemName = 'choiceB'
              choiceLetter = 'B'
            } else if (key === 2) {
              itemName = 'choiceC'
              choiceLetter = 'C'
            } else if (key === 3) {
              itemName = 'choiceD'
              choiceLetter = 'D'
            }

            const letterArray = ['A', 'B', 'C', 'D']

            let graphPercentageWidth
            if (results) {
              const graphScaling = totalResponseCount
              graphPercentageWidth = (parseInt(results[letterArray[key]]) / graphScaling) * 100
            }
            let displayGraph = false
            if (showGraph) {
              displayGraph = true
            }

            let isSelected = false
            if (selectedChoice && selectedChoice === letterArray[key]) {
              isSelected = true
            }

            return (
              <ChoiceButton
                choice={choice}
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                choiceIndex={key}
                isSelected={isSelected}
                onSelectChoice={onSelectChoice}
                colorScheme={colorScheme}
                isSurveyQuestion={isSurvey}
                isReviewScreen={isReviewScreen}
                isAskScreen={isAskScreen}
                showCorrect={showCorrect}
                isScanScreen={isScanScreen}
                isCorrect={choice.correct}
                showResponseCounts={showResponseCounts}
                choiceFontSize={choiceFontSize}
                choiceMediaType={choiceMediaType}
                choiceStyle={choiceStyle}
                isChoiceMedia={isChoiceMedia}
              >

                <StaticLetterBlock
                  index={key}
                  isCorrect={choice.correct}
                  isSurvey={isSurvey}
                  isReviewScreen={isReviewScreen}
                  reportView={reportView}
                  showCorrect={showCorrect}
                  alwaysShowLetterblock={alwaysShowLetterblock}
                />

                {/* CHOICE TEXT */}
                {!isChoiceMedia && (
                  <div className="slide-choice-text">
                    <div dangerouslySetInnerHTML={createMarkup(choice.bodyHtml)} className="slide-choice-content" />
                  </div>
                )}

                {/* CHOICE MEDIA */}
                {isChoiceMedia && (
                  <div className="slide-choice-content">

                    {choiceMediaType === 'image' && !mediaIsZoomable && choice.media.deliveryUrl && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--image slide-choice-mediaContainer--image--static">
                        <StaticSlideChoiceImage
                          choiceLetter={choiceLetter}
                          choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                          image={choice.media}
                          UIInverseScale={UIInverseScale}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'image' && mediaIsZoomable && !choice.media.imageFill && (
                      <div onClick={() => { this.props.zoomMedia(itemName) }} className="slide-choice-mediaContainer slide-choice-mediaContainer--image">
                        <StaticSlideChoiceZoomableFitImage
                          choiceIndex={key}
                          image={choice.media}
                          mediaChoiceWidth={mediaChoiceWidth}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'image' && mediaIsZoomable && choice.media.imageFill && (
                      <div onClick={() => { this.props.zoomMedia(itemName) }} className="slide-choice-mediaContainer slide-choice-mediaContainer--image">
                        <StaticSlideChoiceZoomableFillImage
                          choiceIndex={key}
                          mediaChoiceWidth={mediaChoiceWidth}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'animatedGif' && !mediaIsZoomable && choice.media.fileId && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--gif slide-choice-mediaContainer--gif--static">
                        <StaticSlideChoiceAnimatedGIF
                          choiceLetter={choiceLetter}
                          choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                          gif={choice.media}
                          fullyStatic={!mediaIsPlayable}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'sound' && mediaIsPlayable && !mediaIsZoomable && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--sound">
                        <StaticSlideChoiceSoundPlayer
                          sound={choice.media}
                          slideWidth={slideWidth}
                          questionChoiceCount={choiceCount}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'sound' && mediaIsPlayable && mediaIsZoomable && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--sound slide-choice-mediaContainer--sound--zoomable">
                        <StaticSlideChoiceSoundZoomablePlayer
                          choiceIndex={key}
                          mediaChoiceWidth={mediaChoiceWidth}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'sound' && !mediaIsPlayable && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--sound">
                        <StaticSlideChoiceSoundStatic
                          sound={choice.media}
                          // duration={choice.media.soundDetails.duration}
                          title={choice.media.title}
                          slideWidth={slideWidth}
                          questionChoiceCount={choiceCount}
                          choiceLetter={choiceLetter}
                          choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                        />
                      </div>
                    )}

                    {choiceMediaType === 'video' && mediaIsPlayable && !mediaIsZoomable && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--video">
                        {isYoutubeAllowed ? (
                          <StaticSlideChoiceVideoPlayer
                            choiceIndex={key}
                            slideWidth={slideWidth}
                            slidetemplate={slideTemplate}
                            video={choice.media}
                            start={choice.media.start}
                            end={choice.media.end}
                            videoWidth={640}
                            videoHeight={360}
                            questionChoiceCount={choiceCount}
                          />
                        ) : (
                          <YoutubeBlocked />
                        )}
                      </div>
                    )}
                    {choiceMediaType === 'video' && mediaIsPlayable && mediaIsZoomable && (
                      <div className={`slide-choice-mediaContainer slide-choice-mediaContainer--video${choiceMediaZoomed ? ' slide-choice-mediaContainer--video--choicesAreZoomed ' : ' slide-choice-mediaContainer--video--choicesAreNotZoomed '}${(choiceMediaZoomed && activeZoomedChoiceIndex === key) ? ' slide-choice-mediaContainer--video--choiceIsZoomed ' : ' slide-choice-mediaContainer--video--choiceIsNotZoomed'}`}>
                        {isYoutubeAllowed ? (
                          <StaticSlideChoiceVideoZoomablePlayer
                            choiceIndex={key}
                            slideWidth={slideWidth}
                            slidetemplate={slideTemplate}
                            video={choice.media}
                            start={choice.media.start}
                            end={choice.media.end}
                            videoWidth={640}
                            videoHeight={360}
                            choiceMediaZoomed={choiceMediaZoomed}
                            activeZoomedChoiceIndex={activeZoomedChoiceIndex}
                            xPosSlide={this.props.xPosSlide}
                            yPosSlide={this.props.yPosSlide}
                            zoomMedia={() => { this.props.zoomMedia(itemName) }}
                            viewportWidth={this.props.viewportWidth}
                            viewportHeight={this.props.viewportHeight}
                            questionChoiceCount={choiceCount}
                            playbackItem={playbackItem}
                            isPlaying={isPlaying}
                            updateControlPlayback={this.props.updateControlPlayback}
                            itemName={itemName}
                            pageVisibilityState={this.props.pageVisibilityState}
                            clientPlaybackCommand={this.props.clientPlaybackCommand}
                            clearPlaybackCommand={this.props.clearPlaybackCommand}
                          />
                        ) : (
                          <YoutubeBlocked />
                        )}
                      </div>
                    )}

                    {choiceMediaType === 'video' && !mediaIsPlayable && (
                      <div className="slide-choice-mediaContainer slide-choice-mediaContainer--video">
                        {isYoutubeAllowed ? (
                          <StaticSlideChoiceVideoStatic
                            choiceIndex={key}
                            slideWidth={slideWidth}
                            slidetemplate={slideTemplate}
                            video={choice.media}
                            videoWidth={640}
                            videoHeight={360}
                            start={choice.media.start}
                            end={choice.media.end}
                            questionChoiceCount={choiceCount}
                            choiceLetter={choiceLetter}
                            choiceMediaPromiseResolve={this[`choice${key}MediaPromiseResolve`]}
                          />
                        ) : (
                          <YoutubeBlocked />
                        )}
                      </div>
                    )}

                  </div>
                )}

                {/* CHOICE GRAPH */}
                {displayGraph && (
                  <div className="slide-choice-graph">
                    <div style={{ width: `${graphPercentageWidth}%` }} className="slide-choice-graph-bar" />
                  </div>
                )}

              </ChoiceButton>
            )
          })}
        </div>
      </div>
    )
  }
}

RichStaticSlide.defaultProps = {
  viewType: 'preview',
}

// export default RichStaticSlide
function mapStateToProps(state) {
  return {
    cookieConsents: state.cookieConsents,
  }
}

export default connect(mapStateToProps)(RichStaticSlide)
